<template>
  <div class="profile-addresses">
    <!-- <v-container> -->
    <v-row>
      <v-col class="hidden-sm-and-down" cols="0" md="3">
        <MenuNavigator class="menu-navigator" />
      </v-col>
      <v-col cols="12" md="9">
        <ProfileTitle class="mb-3 mb-sm-0" :category="category" />
        <v-list class="rounded-lg">
          <v-list-item
            v-for="address in currentAddresses"
            :key="address.addressId"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-avatar>
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    v-if="address.selected"
                    color="primary"
                  >
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                  <v-icon v-else @click="selectAddress(address)"
                    >mdi-checkbox-blank-circle-outline</v-icon
                  >
                </v-list-item-avatar>
              </template>
              <span v-if="address.selected">
                {{ $t("profile.addresses.selectedAddress") }}
              </span>
              <span v-else>{{ $t("profile.addresses.selectAddress") }}</span>
            </v-tooltip>
            <!-- :class="{ zebra: index % 2 == 0 }" -->
            <v-row no-gutters align="center">
              <v-col cols="3" sm="3">
                <strong>{{ address.addressName }}</strong>
              </v-col>
              <v-col cols="6">
                <span>
                  {{ address.address1 }} {{ address.addressNumber }} -
                  {{ address.city }} ({{ address.province }})
                </span>
              </v-col>
              <v-col cols="3" class="d-flex justify-end">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      color="error"
                      @click="removeAddress(address.addressId)"
                    >
                      <v-icon large>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("profile.addresses.removeAddress") }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DeliveryService from "~/service/deliveryService";
import MenuNavigator from "@/components/profile/MenuNavigator";
import ProfileTitle from "@/components/profile/ProfileTitle";

import categoryMixins from "~/mixins/category";

export default {
  name: "ProfileAddress",
  components: {
    MenuNavigator,
    ProfileTitle
  },
  mixins: [categoryMixins],
  data() {
    return {
      currentAddresses: [],
      newAddress: {
        addressId: -1,
        addressName: "",
        address1: "",
        address2: "",
        addressNumber: "",
        postalcode: "",
        city: "",
        province: ""
      }
    };
  },
  methods: {
    async fetchAddresses() {
      let vm = this;
      var data = await DeliveryService.getAddressByDeliveryService(6);
      vm.currentAddresses = data.addresses;
    },
    async removeAddress(addressId) {
      const res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        await DeliveryService.removeAddress(addressId);
        await this.fetchAddresses();
      }
    },
    selectAddress(address) {
      DeliveryService.selectAddress(address.addressId).then(function() {
        //go to home page
      });
    },
    addAddress() {
      // this.$router.push({
      //   path: "/edit-addresses/",
      //   name: "/edit-addresses/",
      //   params: {
      //     address: this.newAddress,
      //     newAddress: true
      //   }
      // });
    }
  },
  mounted() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchAddresses();
  }
};
</script>
